<template>
    <div class="home">
        <div class="is-pulled-right"><button class="button is-info is-small" @click="$emit('backToMenu')">Back to Overview </button></div><br /><br />

        <div class="notification is-warning " v-if="loading==true">
            <p>Loading ...</p>
        </div>

        <div class="box" v-if="loading==false">	
              
            
            
            <h4 class="title is-4">Nurses Skills Sheet Questionnaire</h4>
            
            <p>Please tick the box in accordance with the level of expertise as indicated below:</p>
            <ol>
<li>Familiar with the procedure and can perform</li>
<li>Familiar with the procedure but need supervision</li>
<li>Understand the theory of procedure but never task</li>
<li>No knowledge of the procedure</li>
</ol>

            <table class="table  is-hoverable is-fullwidth" >
                <tbody>
                <template v-for=" row in skills_sheet_categories " >
                    <tr :key="'title' + row.id">
                        <th colspan="2">{{ row.name}}</th>
                    </tr>
                    <tr v-for="question in row.questions " :key="'question' + question.id">
                        <td>{{ question.name}}</td>
                        <td width="25%">
                            <button class="button is-small" :class="{'is-success' : question.answer == 1}" :disabled="working==true" @click="saveSkillsSheetAnswer(question, 1)">1</button>
                            <button class="button is-small " :class="{'is-warning' : question.answer == 2}" :disabled="working==true"  @click="saveSkillsSheetAnswer(question, 2)">2</button>
                            <button class="button is-small " :class="{'is-warning' : question.answer == 3}" :disabled="working==true"  @click="saveSkillsSheetAnswer(question, 3)">3</button>
                            <button class="button is-small " :class="{'is-danger' : question.answer == 4}" :disabled="working==true"  @click="saveSkillsSheetAnswer(question, 4)">4</button></td>
                    </tr>
                    
                </template>
               
            </tbody>
            </table>

           
            
            <br />  
            <button class="button is-success" @click="checkAnswers()">I've Completed All Of The Questions</button>
            
            
          </div>
          
      </div>
  </template>

<script>
    import UtilityService from '@/services/UtilityService.js';
    import MemberApplicationService from '@/services/MemberApplicationService.js';
    
    export default {
      name: 'ViewApplication',
        mixins: [],
        props: ['application'],
        data: function () {
            return {
                   application_code : this.$route.params.id,
                   loading:false,
                   accessToken: '',
                   skills_sheet_categories: [],
                   working:false
            }
        },
        created() {
          this.getSkillsSheet()
          
        },
        methods: {
            
            async getSkillsSheet() {
                this.loading = true;
                UtilityService.getSimpleApiData(this.accessToken, "web/applications/" + this.application_code + '/skills_sheet?nmc_only=1').then(
                    response => {
                        this.skills_sheet_categories = response ;
                        this.loading = false;
                    }
                );
            },
            async saveSkillsSheetAnswer(question, value) {
                
                this.working = true
                var formdata = { 
                    skills_sheet_question_id : question.id,
                    answer: value
                }; 

                
                MemberApplicationService.saveSkillsSheetAnswer(this.accessToken, this.application_code, formdata)
                .then((response) => {
                    console.log(response)
                    
                    question.answer=value
                    this.working = false
                    
                    }).catch((error) => {
                        if(error.response.status === 422)
                        {
                            console.log("Error:" + JSON.stringify(error.response.data));
                            alert("Something went wrong. Make sure you have filled in all the fields.")
                        }
                        
                        
                        this.working = false
                });
                

                
            },
            checkAnswers ()
            {
                if(this.allQuestionsAnsweredOne == true)
                {
                    this.$emit('backToMenu')
                }
                else{
                    alert("Please supply an answer to all questions.")
                    return false
                }
                
            }
            
           
        },
        computed: {
            allQuestionsAnsweredOne() {
                // Loop through each category
                for (let category of this.skills_sheet_categories) {
                    // Loop through questions in each category
                    for (let question of category.questions) {
                        // Check if the answer is not null
                        if (question.answer === null) {
                            return false; // If any answer is not 1, return false
                        }
                    }
                }
                return true; // If all answers are 1, return true
            }
        }
    }
    </script>